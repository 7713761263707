











































































































































































































import PatientApi from "../../api/patient.api";
import { Component, Prop } from "vue-property-decorator";
import { Patient } from "@/modules/patient/types/patient.type";
import moment from "moment";
import axios, { CancelTokenSource } from "axios";
import { Getter } from "vuex-class";
import { PatientStatus } from "../../types/patient-status.type";
import mixins from "vue-class-component";
import SmileMixin from "@/mixins/smile.mixin";
import { ContactBookmark } from "@/modules/contacts/types/contact-bookmark.type";
import { Tag } from "../../types/tag.type";

@Component
export default class PatientForm extends mixins(SmileMixin) {
  @Prop({ required: true }) public editPatient: Patient;
  @Getter("auth/isShowingParentsFields") isShowingParentsFields: boolean;
  @Getter("auth/patientStatuses") patientStatuses: PatientStatus[];
  @Getter("auth/isIdNumberInputNumeric") isIdNumberInputNumeric: boolean;
  @Getter("auth/showPatientExtraReferredByPatient") showPatientExtraReferredByPatient: boolean;
  @Getter("auth/showPatientExtraInsuranceCompany") showPatientExtraInsuranceCompany: boolean;
  @Getter("auth/showPatientExtraReferredByContactId") showPatientExtraReferredByContactId: boolean;

  public isDirty = false;
  public errors: any = false;
  public editLoading = false;
  public gender = this.editPatient ? this.editPatient.gender : null;
  public id_number = this.editPatient ? this.editPatient.id_number : "";
  public first_name = this.editPatient ? this.editPatient.first_name : "";
  public last_name = this.editPatient ? this.editPatient.last_name : "";
  public phone = this.editPatient ? this.editPatient.phone : "";
  public phone_note = this.editPatient ? this.editPatient.phone_note : "";
  public email = this.editPatient ? this.editPatient.email : "";
  public address = this.editPatient ? this.editPatient.address : "";
  public city = this.editPatient ? this.editPatient.city : "";
  public zip_code = this.editPatient ? this.editPatient.zip_code : "";
  public referrer = this.editPatient ? this.editPatient.referrer : "";
  public birth_date = this.editPatient ? moment(this.editPatient.birth_date).format("DD/MM/YYYY") : "";
  public statusId = this.editPatient ? this.editPatient.status_id : null;
  public tagIds = this.editPatient ? this.convertPatientTagsToTagIds() : [];
  public contact1_phone = this.editPatient ? this.editPatient.contact1_phone : "";
  public contact1_email = this.editPatient ? this.editPatient.contact1_email : "";
  public contact1_name = this.editPatient ? this.editPatient.contact1_name : "";
  public contact2_phone = this.editPatient ? this.editPatient.contact2_phone : "";
  public contact2_email = this.editPatient ? this.editPatient.contact2_email : "";
  public contact2_name = this.editPatient ? this.editPatient.contact2_name : "";
  public home_phone = this.editPatient && this.editPatient.attributes && this.editPatient.attributes.home_phone ? this.editPatient.attributes.home_phone : "";
  public home_phone_note = this.editPatient && this.editPatient.attributes && this.editPatient.attributes.home_phone_note ? this.editPatient.attributes.home_phone_note : "";
  public referred_by_patient = this.editPatient && this.editPatient.attributes && this.editPatient.attributes.referred_by_patient ? this.editPatient.attributes.referred_by_patient : "";
  public referred_by_contact_ids = this.editPatient && this.editPatient.attributes && this.editPatient.attributes.referred_by_contact_ids ? this.editPatient.attributes.referred_by_contact_ids : [];
  public insurance_company = this.editPatient && this.editPatient.attributes && this.editPatient.attributes.insurance_company ? this.editPatient.attributes.insurance_company : "";
  private activeRequest: CancelTokenSource = null;
  public isNewPatientIdNumberAlreadyExists = false;
  public isNewPatientIdNumberArchivedExists = false;
  public isLoadingNewPatientIdNumber = false;
  public isLoadingNewPatientName = false;
  public isNewPatientNameAlreadyExists = false;
  public isNewPatientNameArchivedExists = false;

  public advancedContactSearch(item: any, queryText: string, itemText: string) {
    const queries = queryText.split(" ").map((subText) => {
      return itemText.includes(subText);
    });
    return !queries.includes(false);

  }

  get tags() {
    return this.$store.getters["auth/tags"]
      .map((tag: Tag) => ({
        "text": tag.name,
        "value": tag.id,
      }));
  }

  public convertPatientTagsToTagIds() {
    return this.editPatient.tags.map(tag => tag.id);
  }

  public async onNewPaitentIdNumberChanged() {
    this.isLoadingNewPatientIdNumber = true;
    this.isNewPatientIdNumberAlreadyExists = false;
    this.isNewPatientIdNumberArchivedExists = false;

    if (this.activeRequest) {
      this.activeRequest.cancel();
    }
    this.activeRequest = axios.CancelToken.source();

    if (!this.id_number) {
      this.isLoadingNewPatientIdNumber = false;
      return;
    }

    try {
      const patientsByIdNumber = await PatientApi.quickSearch(this.id_number, true, this.activeRequest);
      this.isLoadingNewPatientIdNumber = false;
      if (patientsByIdNumber.data.data.find(({ id_number, deleted_at, id }) => (id_number === this.id_number && !deleted_at && (!this.editPatient || this.editPatient.id !== id)))) {
        this.isNewPatientIdNumberAlreadyExists = true;
      } else if (patientsByIdNumber.data.data.find(({ id_number, id }) => (id_number === this.id_number && (!this.editPatient || this.editPatient.id !== id)))) {
        this.isNewPatientIdNumberArchivedExists = true;
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }

      this.isLoadingNewPatientIdNumber = false;
    }
  }

  public async onNewPaitentNameChanged() {
    this.isLoadingNewPatientName = true;
    this.isNewPatientNameAlreadyExists = false;
    this.isNewPatientNameArchivedExists = false;

    if (this.activeRequest) {
      this.activeRequest.cancel();
    }
    this.activeRequest = axios.CancelToken.source();

    if (!this.first_name || !this.last_name) {
      this.isLoadingNewPatientName = false;
      return;
    }

    try {
      const patientsByName = await PatientApi.quickSearch(this.first_name + " " + this.last_name, true, this.activeRequest);
      this.isLoadingNewPatientName = false;
      if (patientsByName.data.data.find(({ first_name, last_name, deleted_at, id }) => (first_name === this.first_name && last_name === this.last_name && !deleted_at && (!this.editPatient || this.editPatient.id !== id)))) {
        this.isNewPatientNameAlreadyExists = true;
      } else if (patientsByName.data.data.find(({ first_name, last_name, id }) => (first_name === this.first_name && last_name === this.last_name && (!this.editPatient || this.editPatient.id !== id)))) {
        this.isNewPatientNameArchivedExists = true;
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }

      this.isLoadingNewPatientName = false;
    }
  }

  public closeDialog() {
    this.$emit("close");
  }

  public submitForm() {
    this.editLoading = true;
    this.errors = false;

    const payload = {
      id_number: this.id_number,
      first_name: this.first_name,
      last_name: this.last_name,
      phone: this.phone,
      phone_note: this.phone_note,
      email: this.email,
      gender: this.gender ? this.gender : null,
      address: this.address,
      city: this.city,
      zip_code: this.zip_code,
      status_id: this.statusId,
      tag_ids: this.tagIds,
      referrer: this.referrer,
      birth_date: this.birth_date ? moment(this.birth_date, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
      contact1_phone: this.contact1_phone,
      contact1_name: this.contact1_name,
      contact1_email: this.contact1_email,
      contact2_phone: this.contact2_phone,
      contact2_name: this.contact2_name,
      contact2_email: this.contact2_email,
      attributes: {
        home_phone: this.home_phone,
        home_phone_note: this.home_phone_note,
        referred_by_patient: this.referred_by_patient,
        referred_by_contact_ids: this.referred_by_contact_ids,
        insurance_company: this.insurance_company,
      }
    };

    const attempt = this.editPatient ? PatientApi.update(this.editPatient.id, payload) : PatientApi.create(payload);
    attempt
      .then(patient => {
        this.editLoading = false;

        this.$toastr.s(this.$t(this.editPatient ? "patient_updated" : "patient_created"));
        this.closeDialog();
        this.$emit(this.editPatient ? "patientUpdated" : "patientCreated", patient);
      })
      .catch(err => {
        this.editLoading = false;
        if (err.response && err.response.data && err.response.data.errors) {
          this.errors = err.response.data.errors;
        } else {
          this.errors = this.$t("temporary_error");
        }
      });
  }

  get insuranceCompanyContacts() {
    const insuranceBookmarks = (this.$store.getters["auth/contacts"] as ContactBookmark[]).filter(({ display_type }) => (display_type && display_type.includes("insurance")));
    return insuranceBookmarks.map(({ contacts }) => (contacts))
      .flat();
  }

  get referenceContacts() {
    const referenceBookmarks = (this.$store.getters["auth/contacts"] as ContactBookmark[]).filter(({ display_type }) => (display_type && display_type.includes("reference")));
    return referenceBookmarks.map(({ contacts }) => (contacts))
      .flat();
  }

  public bookmarkNameById(bookmarkId: number) {
    const referenceBookmarks = (this.$store.getters["auth/contacts"] as ContactBookmark[]);
    if (referenceBookmarks.length > 1) {
      const bookmark = referenceBookmarks.find(bookmark => bookmark.id === bookmarkId);
      return bookmark.name;
    }
  }

  public markDirty() {
    this.isDirty = true;
  }
}
